<template>
    <Layout>

        <div class="row" id="page">
            <div class="col-12">

                <div class="page-separator">
                    <div class="page-separator__text">Informações Básicas</div>
                </div>

                <div class="card">
                    <div class="card-body p-4">
                        <div class="row">

                            <div class="col-12 mb-3">
                                <label class="form-label">Título do Formulário <span
                                    class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="form.title"
                                       placeholder="Título do Formulário" required>
                            </div>

                            <div class="col-12 mb-3">
                                <label class="form-label">Assunto/Descrição <span class="text-danger">*</span></label>
                                <vue-editor ref="editor1" class="editorSize" aria-required="true" v-model="form.subject"
                                            required :editor-toolbar="customToolbar"/>
                            </div>
                            <div class="col-12 mb-3 mt-4">
                                <div>
                                    <label class="form-label">Questões:</label>
                                    <i class="fa fa-sort pointer float-right" @click="setType"
                                       v-if="Object.keys(questions).length > 0"></i>
                                </div>
                                <hr class="m-0">
                            </div>
                        </div>

                        <ListQuestions
                            ref="listQuestionsComponent"
                            v-if="Object.keys(questions).length > 0"
                        />

                        <div v-else>
                            <div class="card-title">
                                Nenhuma questão cadastrada
                            </div>
                        </div>

                        <FormQuestion
                            @cancel-form="addQuestion=false"
                            v-if="addQuestion"
                        />

                        <div class="row" v-else>
                            <div class="col-12 mt-3">
                                <button type="button" class="btn btn-outline-primary mb-24pt mb-sm-0"
                                        @click="addQuestion=true">
                                    Adicionar Questão
                                </button>
                            </div>

                            <div class="col">
                                <button type="button" @click.prevent="$router.push('/formularios')"
                                        class="btn btn-primary btn-save" style="right:150px;">
                                    Voltar
                                </button>
                            </div>
                            <div class="col">
                                <button type="button" @click.prevent="save" id="save" class="btn btn-primary btn-save">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue';
import FormQuestion from "@/views/forms/form-question.vue";
import ListQuestions from "@/views/forms/list-questions.vue";
import {VueEditor} from "vue2-editor";
import {endLoading, startLoading} from "@/components/composables/spinners";
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import http from "@/http";
import {mapState} from "vuex";

export default {
    components: {
        Layout,
        VueEditor,
        FormQuestion,
        ListQuestions
    },
    data() {
        return {
            customToolbar: [
                [{'size': ['small', false, 'large', 'huge']}],
                ["bold", "italic"],
                [{list: "ordered"}, {list: "bullet"}],
            ],
            form: {
                id: new Date().getTime() + '_question',
                title: '',
                subject: '',
            },
            addQuestion: false,
            formId: '',
        }
    },
    methods: {
        setType() {
            this.$refs.listQuestionsComponent.setType('drag');
        },

        // initializeEditors() {
        //     const editorRefs = ['editor1', 'editor2', 'editor3'];
        //     editorRefs.forEach(ref => {
        //         const editor = this.$refs[ref].quill;
        //         editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        //             delta.ops.forEach(op => {
        //                 if (op.attributes) {
        //                     if ('color' in op.attributes) {
        //                         delete op.attributes.color;
        //                     }
        //                     if ('background' in op.attributes) {
        //                         delete op.attributes.background;
        //                     }
        //                 }
        //             });
        //             return delta;
        //         });
        //     });
        // },

        save() {
            this.$store.commit('api/setSpinner', true);
            startLoading('page', 'save');

            // Campos obrigatórios a serem preenchidos
            const required = {
                title: 'Título',
                subject: 'Assunto',
            };

            // Verificando se existe campo obrigatórios em branco
            if (!ValidateForm(this.$root, this.form, required)) {
                endLoading('page', 'save');
                this.$store.commit('api/setSpinner', false);
                return;
            }

            // Verificando se ao menos uma questão está sendo cadastrada
            if (this.questions.length === 0) {
                endLoading('page', 'save');
                this.$notifyError('Insira ao menos uma questão!')
                this.$store.commit('api/setSpinner', false);
                return;
            }

            // Inserindos dados no POST
            const data = toFormData(this.form);

            // Inserindo dados das questões no POST
            let missingInformation = false;
            this.questions.forEach((element, index) => {
                if (element.question === '' || element.type === '' || (element.type > 1 && element.alternatives.length === 0)) {
                    endLoading('page', 'save');
                    this.$notify({
                        title: `<i class="fa fa-times-circle mr-2 mt-2"></i> <span>A Questão ` + (index + 1) + ` está com informações faltando!</span>`,
                        type: 'error',
                        duration: 3000,
                        closeOnClick: true
                    });
                    this.$store.commit('api/setSpinner', false);
                    missingInformation = true;
                }

                data.append(`question[${index}][question_text]`, element.question);
                data.append(`question[${index}][id]`, element.id);
                data.append(`type[${index}]`, element.type);
                if (element.alternatives.length > 0) data.append(`alternatives[${index}]`, JSON.stringify(element.alternatives));
            })

            if (missingInformation) return;

            // Enviar dados ao back end
            http.post('forms', data)
                .then(response => {
                    localStorage.setItem('new_form', String(new Date().getTime()))
                    this.$notifySuccess(response.data);
                    setTimeout(() => {
                        this.$router.back();
                    }, 200)
                })
                .catch(e => {
                    console.error('Form Register: ', e);
                    this.$notifyError(e);
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        // Buscar dados do formulário
        getData(id) {
            this.$store.commit('api/setSpinner', true);
            startLoading('page', 'save');
            http.get('forms/' + id)
                .then(response => {
                    this.form = response.data.data[0];
                    this.$store.commit('form/setQuestions', response.data.data[0].questions)
                })
                .catch(e => {
                    console.error(e);
                    this.$notifyError('Erro ao carregar dados do Formulário!');
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },
    },

    mounted() {
        this.formId = this.$route.params.id
        if (this.formId) this.getData(this.formId);
        // this.initializeEditors();
    },

    computed: {
        ...mapState({
            questions: state => state.form.questions
        })
    },
}
</script>
