<template>
    <BRow v-if="preview" class="position-relative" @mouseenter="mouseOn = true" @mouseleave="mouseOn = false"
          style="min-height: 40px">
        <BCol class="d-flex mt-3" sm="12" :class="{'opacity-25': mouseOn}">
            <b class="mr-2">{{ index + 1 }}.</b>
            <span>{{ question.question }}</span>
        </BCol>

        <BCol class="d-flex ml-3" :class="{'opacity-25': mouseOn}" sm="12"
              v-for="(alternative, i) in question.alternatives"
              :key="`alternative-${alternative.id}`">
            <b class="mr-2">{{ alphabet[i] }})</b>
            <span class="italic">{{ alternative.alternative }}</span>
        </BCol>

        <BCol class="position-absolute d-flex justify-content-center" style="top: calc(50% - 15px)" v-if="mouseOn">
            <span class="bg-soft-info rounded-circle mx-2 my-auto text-center balloon pointer"
                  @click="preview = false; mouseOn = false">
                <i class="fa fa-pencil-alt text-info"></i>
            </span>
            <span class="bg-soft-danger rounded-circle mx-2 my-auto text-center balloon pointer"
                  @click="removeQuestion">
                <i class="fa fa-trash text-danger"></i>
            </span>
        </BCol>
    </BRow>

    <BRow v-else>

        <BCol class="my-3" sm="12">
            <hr class="text-primary">
            <h6>Editar Questão</h6>
        </BCol>

        <BCol class="mb-3" md="6">
            <label>Questão <span class="text-danger">*</span></label>
            <input type="text" placeholder="Questão" class="form-control" v-model="edit.question">
        </BCol>

        <BCol class="mb-3" md="6">
            <label>Categoria <span class="text-danger">*</span></label>
            <select name="category" v-model="question.type" class="form-control" @change="resetAlternatives">
                <option value="">Selecione</option>
                <option value="1">Dissertativa</option>
                <option value="2">Única Escolha</option>
                <option value="3">Multipla escolha</option>
            </select>
        </BCol>

        <BCol sm="12" class="mb-3" v-if="edit.type > 1">
            <label>Nova alternativa <span class="text-danger">*</span></label>
            <input type="text" class="form-control" v-model="alternative"
                   @keyup.enter="addAlternative"
                   @keydown.tab.prevent="addAlternative"
                   placeholder="Digite a alternativa e precione Enter ou Tab..."
            >
        </BCol>

        <BCol sm="12">
            <BBadge variant="light" class="w-100 text-start p-2 mb-1 d-flex justify-content-between"
                    v-for="(a, i) in edit.alternatives"
                    :key="`alternative-` + a.id">
                <span class="fs-12" style="white-space: break-spaces">{{ a.alternative }}</span>
                <div class="my-auto">
                    <i class="fa fs-12 fa-times-circle my-auto text-muted pointer" @click="removeAlternative(i)"></i>
                </div>
            </BBadge>
        </BCol>

        <BCol class="text-end mt-3" sm="12">
            <button class="btn btn-outline-danger" @click="cancelEdit">Cancelar</button>
            <button class="btn btn-outline-success ml-1" @click="editQuestion">Salvar Alterações</button>
        </BCol>
    </BRow>
</template>

<script>
import Vue from 'vue';

export default {
    props: {
        question: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            edit: JSON.parse(JSON.stringify(this.$store.state.form.questions[this.index])),
            alternative: '',
            preview: true,
            mouseOn: false
        };
    },
    methods: {
        addAlternative() {
            if (this.alternative.trim() === '') {
                this.$notifyError('Você precisa adicionar a alternativa!');
                return;
            }

            this.edit.alternatives.push({
                id: new Date().getTime() + '_alter',
                alternative: this.alternative
            });

            this.alternative = '';
        },

        removeAlternative(i) {
            const data = {
                index: this.index,
                i
            }
            this.$store.commit('form/removeAlternative', data);
            this.edit.alternatives.splice(i, 1);
        },

        resetAlternatives() {
            if (this.edit.type === '1') this.edit.alternatives = [];
        },

        editQuestion() {
            // const data = {
            //     question: this.edit,
            //     index: this.index
            // };
            // this.$store.commit('form/editQuestion', data);
            Vue.set(this.$store.state.form.questions, this.index, this.edit);
            this.preview = true;
        },

        removeQuestion() {
            this.$store.commit('form/removeQuestion', this.index);
        },

        cancelEdit() {
            this.preview = true;
            this.edit = JSON.parse(JSON.stringify(this.$store.state.form.questions[this.index]));
        }
    }
};
</script>
