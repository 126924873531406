<template>
    <div>
        <div class="row mt-3">
            <div class="col-12 mb-3">
                <label class="form-label">Questão <span class="text-danger">*</span></label>
                <textarea class="textareaSize form-control" aria-required="true" v-model="question.question"
                          required> </textarea>
            </div>
            <div class="col-md-4 mb-3">
                <label class="form-label">Tipo <span class="text-danger">*</span></label>
                <select name="category" v-model="question.type" class="form-control" @change="resetAlternatives">
                    <option value="">Selecione</option>
                    <option value="1">Dissertativa</option>
                    <option value="2">Única Escolha</option>
                    <option value="3">Multipla escolha</option>
                </select>
            </div>
            <div class="col-md-12 mb-3" v-if="question.type == '2' || question.type == '3'">
                <label class="form-label">Alternativas <span class="text-danger">*</span></label>
                <input
                    class="form-control"
                    v-model="currentAlternative"
                    @keyup.enter="addAlternative"
                    @keydown.tab.prevent="addAlternative"
                    placeholder="Digite a alternativa e precione Enter ou Tab..."
                />

                <div class="tag mt-2" v-for="(alternative, index) in question.alternatives" :key="index">
                    <span v-if="typeof alternative === 'object' ">{{ alternative.alternative }}</span>
                    <span v-else>{{ alternative }}</span>
                    <span class="remove-tag" @click="removeAlternative(index)">×</span>
                </div>

            </div>

            <div class="col-12 text-end">
                <button type="button" class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                <button type="button" class="btn btn-outline-success ml-2" @click="save">Salvar</button>
            </div>

        </div>
    </div>
</template>

<script>

import {ValidateForm} from "@/components/composables/cruds";
import {messageSwal} from "@/components/composables/messages";

export default {

    data() {
        return {
            question: {
                id: new Date().getTime() + '_ques',
                question: '',
                type: '',
                required: true,
                alternatives: []
            },
            currentAlternative: '',
        }
    },

    methods: {
        // salvar questão enviando para componente pai
        async save() {

            let result = true;
            if (this.currentAlternative.trim() !== '') {
                result = await messageSwal(
                    "Atenção!",
                    "Você não salvou todas as alternativas! Deseja mesmo continuar?",
                    "warning"
                )
            }

            if (result) {

                // Validando formulário
                let required = {
                    question: 'Questão',
                    type: 'Categoria',
                }

                if (this.question.type > 1) required.alternatives = 'Alternativas';

                if (!ValidateForm(this.$root, this.question, required)) {
                    return;
                }

                this.$store.commit('form/addQuestion', this.question);

                this.cancel();
            }
        },

        resetAlternatives() {
            if (this.question.type === '1') this.question.alternatives = [];
        },

        cancel() {
            this.$emit('cancel-form');
            this.question = {
                id: new Date().getTime() + '_ques',
                question: '',
                type: '',
                required: true,
                alternatives: []
            };
        },

        // Adicionar novas alternativas
        addAlternative() {
            const now = new Date();
            if (this.currentAlternative.trim() !== '' && !this.question.alternatives.includes(this.currentAlternative)) {
                this.question.alternatives.push({
                        id: now.getTime() + '_alt',
                        alternative: this.currentAlternative.trim()
                    }
                );
                this.currentAlternative = '';
            }
        },

        // Removendo alternativa
        removeAlternative(index) {
            this.question.alternatives.splice(index, 1);
        },
    },

}
</script>


<style scoped>
.textareaSize {
    width: 100%;
    height: 80px;
}

.alternatives-input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
}

.tag {
    background-color: #efefef;
    margin: 2px;
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.remove-tag {
    margin-left: auto;
    cursor: pointer;
}
</style>
