<template>
    <div>
        <div ref="mainContainer">
            <div v-for="item in items" :key="item.id" class="group">
                <div class="group-header">
                    {{ item.question }}
                </div>
                <div ref="subContainer" class="group-items">
                    <div
                        v-for="subItem in item.alternatives"
                        :key="subItem.id"
                        class="sortable-item"
                    >
                        {{ subItem.alternative }}
                    </div>
                </div>
            </div>
        </div>

        <BRow>
            <BCol class="text-end mt-3" sm="12">
                <button class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                <button class="btn btn-outline-success ml-1" @click="save">Salvar Alterações</button>
            </BCol>
        </BRow>
    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    data() {
        return {
            items: [],
        };
    },
    mounted() {
        this.items = JSON.parse(JSON.stringify(this.$store.state.form.questions));
        this.$nextTick(() => {
            // Configura o SortableJS para o container principal (não pode arrastar para subgrupos)
            Sortable.create(this.$refs.mainContainer, {
                group: {
                    name: 'main-group',
                    pull: 'clone', // permite apenas clonar, mas não mover para subgrupos
                    put: false,    // impede que os itens principais sejam colocados em outro grupo
                },
                animation: 150,
                onEnd: (event) => {
                    const movedItem = this.items.splice(event.oldIndex, 1)[0];
                    this.items.splice(event.newIndex, 0, movedItem);
                },
            });

            // Configura o SortableJS para cada subgrupo
            this.items.forEach((item, index) => {
                const subContainer = this.$refs.subContainer[index];
                Sortable.create(subContainer, {
                    group: {
                        name: 'sub-group-' + index,
                        pull: true,  // permite puxar itens de subgrupos
                        put: ['sub-group-' + index], // permite colocar apenas dentro do mesmo subgrupo
                    },
                    animation: 150,
                    onEnd: (event) => {
                        const movedSubItem = item.alternatives.splice(event.oldIndex, 1)[0];
                        item.alternatives.splice(event.newIndex, 0, movedSubItem);
                    },
                });
            });
        });
    },
    methods: {
        cancel() {
            this.$emit('set-type', 'preview');
            this.items = JSON.parse(JSON.stringify(this.$store.state.form.questions));
        },
        save() {
            this.$store.commit('form/setQuestions', this.items);
            this.$emit('set-type', 'preview');
        }
    }
};
</script>

<style scoped>
.group {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
}

.group-header {
    font-weight: bold;
    margin-bottom: 5px;
    cursor: grab;
}

.group-items {
    padding-left: 20px;
}

.sortable-item {
    padding: 5px;
    margin-bottom: 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: grab;
    user-select: none;
}
</style>
