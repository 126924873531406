import { render, staticRenderFns } from "./drag.vue?vue&type=template&id=6e49e53d&scoped=true"
import script from "./drag.vue?vue&type=script&lang=js"
export * from "./drag.vue?vue&type=script&lang=js"
import style0 from "./drag.vue?vue&type=style&index=0&id=6e49e53d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e49e53d",
  null
  
)

export default component.exports