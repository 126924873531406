<template>
    <div>
        <div v-if="type === 'preview'">
            <div v-for="(question, index) in questions" :key="`get-question-${question.id}`">
                <Preview
                    :question="question"
                    :index="index"
                />
            </div>
        </div>

        <Drag v-else
            @set-type="setType"
        />
    </div>
</template>

<script>
import Preview from "@/views/forms/preview.vue";
import Drag from './drag.vue'
import {mapState} from "vuex";

export default {
    components: {
        Preview,
        Drag
    },
    data() {
        return {
            type: 'preview'
        };
    },
    computed: {
        ...mapState({
            questions: state => state.form.questions
        })
    },
    methods: {
        setType(newValue) {
            this.type = newValue;
        }
    }
};
</script>
